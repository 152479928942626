import Vue from 'vue'
import Router from 'vue-router'
import difference from 'lodash/difference'
import Profile from '@/store/models/profile'
import Config from '@/store/models/config'
import Committee from '@/components/pages/Committee'
import CommitteeEdit from '@/components/pages/CommitteeEdit'
import Meeting from '@/components/pages/Meeting'
import MeetingDocument from '@/components/pages/MeetingDocument'
import MeetingReadDocuments from '@/components/pages/MeetingReadDocuments'
import Protocol from '@/components/pages/Protocol'
import AgendaItem from '@/components/pages/AgendaItem'
import Attachment from '@/components/pages/Attachment'
import Proposal from '@/components/pages/Proposal'
import Root from '@/components/pages/Root'
import NotFound from '@/components/pages/NotFound'
import NoCommittee from '@/components/pages/NoCommittee'
import { api } from '@/api'
import PersonalDocument from '@/components/pages/PersonalDocument'
import store from '@/store/index'
import Search from '@/components/pages/Search'
import Vorstoesse from '@/components/pages/Vorstoesse.vue'

Vue.use(Router)

export default function createRouter(config = {}) {
  const router = new Router({
    mode: 'history',
    base: config.path_prefix,
    routes: [
      {
        path: '',
        name: 'root',
        component: Root,
      },
      {
        path: '/no-committee',
        name: 'no-committee',
        component: NoCommittee,
      },
      {
        path: '/committees/:id',
        name: 'committee',
        component: Committee,
      },
      {
        path: '/committees/:id/edit',
        name: 'committee-edit',
        component: CommitteeEdit,
      },
      {
        path: '/meetings/:id',
        name: 'meeting',
        component: Meeting,
      },
      {
        path: '/meetings/:id/read_documents',
        name: 'meeting_read_documents',
        component: MeetingReadDocuments,
      },
      {
        path: '/meeting_documents/:id',
        name: 'meetingdocument',
        component: MeetingDocument,
      },
      {
        path: '/protocols/:id',
        name: 'protocol',
        component: Protocol,
      },
      {
        path: '/agenda_items/:id',
        name: 'agendaitem',
        component: AgendaItem,
      },
      {
        path: '/personaldocument/:id',
        name: 'personaldocument',
        component: PersonalDocument,
      },
      {
        path: '/proposals/:id',
        name: 'proposal',
        component: Proposal,
      },
      {
        path: '/attachments/:id',
        name: 'attachment',
        component: Attachment,
      },
      {
        path: '/search',
        name: 'search',
        component: Search,
      },
      {
        path: '/meetings/:id/vorstoesse',
        name: 'vorstoesse',
        component: Vorstoesse,
      },
      {
        path: '/404',
        name: '404',
        component: NotFound,
      },
      {
        path: '*',
        component: NotFound,
      },
    ],
  })

  router.beforeResolve(async (to, from, next) => {
    const matched = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)

    // As we're routing through the Shell component,
    // we always have the component we want (e.g. Committee.vue)
    // at the last positon of the matched
    const component = matched[matched.length - 1]

    // If there's a diffrence between the previous matched
    // and the matched, it's a route change. (/foo/1 -> /bar/2)
    const activated = difference(matched, prevMatched)
    const routeChanged = !!activated.length

    if (routeChanged && component.initialAsyncData) {
      await component.initialAsyncData({ ...to.params, ...to.query }, next)
    }

    if (component.asyncData) {
      await component.asyncData({ ...to.params, ...to.query }, next)
    }

    if (Profile.query().count() === 0) {
      await Profile.fetch()
    }
    if (Config.query().count() === 0) {
      await Config.fetch()
      store.dispatch('application/setConfig')
      store.dispatch('appswitcher/setAppSwitcher')
    }

    return next()
  })

  // Trap 404 responses
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.config.method === 'get' && error.response.status === 404 && error.config.url !== '/api/v1/config/') {
        router.replace({ name: '404' })
      }
      return Promise.reject(error)
    },
  )

  return router
}
