<template>
  <TitledCard :title="$t('vorstossCard')">
    <template #actions>
      <v-btn text color="primary" @click="openVorstossDialog">{{ $t('newVorstossButton') }}</v-btn>
    </template>
    <VorstossDialog ref="vorstossDialog" />
  </TitledCard>
</template>
<script>
export default {
  name: 'VorstossCard',
  props: {
    committee: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openVorstossDialog() {
      this.$refs.vorstossDialog.openWithCommittee(this.committee)
    },
  },
}
</script>
