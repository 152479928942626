<template>
  <v-dialog v-model="isOpen" width="800">
    <v-card>
      <v-card-title>
        <h2 class="heading">{{ $t('submitVorstossDialogTitle') }}</h2>
      </v-card-title>
      <v-card-text>
        <i18n path="submitVorstossDialogText" tag="p">
          <strong>{{ vorstoss.title }}</strong>
        </i18n>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text color="error" @click="close">{{ $t('Cancel') }}</v-btn>
        <v-btn data-testid="confirm-submit-button" text color="primary" @click="submit">
          {{ $t('submitVorstossSubmit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import get from 'lodash/get'

export default {
  name: 'VorstossSubmitDialog',
  data() {
    return {
      isOpen: false,
      vorstoss: {},
    }
  },
  methods: {
    async submit() {
      try {
        await api.post(createLink('vorstoss/{id}/submit', { id: this.vorstoss.id }))
        this.close()
        this.$emit('submitted')
        this.notifySuccess(this.$t('vorstossCardSubmitSuccess'))
      } catch (error) {
        const message = get(error, 'response.data.non_field_errors', []).join(' ')
        this.notifyError(this.$t('SubmitVorstossCardSubmitError', { message }))
        throw error
      }
    },
    close() {
      this.vorstoss = {}
      this.isOpen = false
    },
    open(vorstoss) {
      this.vorstoss = vorstoss
      this.isOpen = true
    },
  },
}
</script>
