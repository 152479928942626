import linkify from 'linkify-string'
import BaseModel from '@/store/models/base'
import { toLocaleDateTimeString } from '@/filters'
import i18n from '@/i18n/index'

export default class Meeting extends BaseModel {
  static entity = 'meeting'

  static apiPath = 'meetings'

  static fields() {
    return {
      id: this.attr(null),
      location: this.attr(null),
      start: this.attr('', (v) => new Date(v)),
      title: this.string(''),
      description: this.attr(null),
      imported_at: this.attr('', (v) => new Date(v)),
      concluded: this.attr('', (v) => (v ? new Date(v) : null)),
      updated: this.attr('', (v) => new Date(v)),
      committee_id: this.number(0),
      committee: this.belongsTo('committee', 'committee_id'),
      agendaitems: this.hasMany('agendaitem', 'meeting_id'),
      protocol_id: this.attr(null),
      protocol: this.hasOne('protocol', 'meeting_id'),
      notes: this.morphMany('note', 'object_id', 'content_type'),
      meetingDocuments: this.hasMany('meetingdocument', 'meeting_id'),
      is_current: this.boolean(false),
      is_next: this.boolean(false),
      last_seen: this.attr(null),
      tagesordnung_slots: this.attr(null),
      has_tagesordnung: this.boolean(false),
      tagesordnung_slot_notes: this.attr({}),
    }
  }

  static get upcoming() {
    return this.query()
      .orderBy('start', 'asc')
      .where((m) => new Date(m.start) >= new Date())
  }

  static get past() {
    return this.query()
      .orderBy('start', 'desc')
      .where((m) => new Date(m.start) < new Date())
  }

  get linkifiedDescription() {
    return linkify(this.description)
  }

  get ISODateString() {
    return new Date(this.start).toISOString().substr(0, 10)
  }

  get hasProtocol() {
    return !!this.protocol_id
  }

  get fullTitle() {
    return this.title || i18n.t('meeting_from', { date: toLocaleDateTimeString(this.start) })
  }

  get breadcrumbText() {
    return this.fullTitle
  }

  get hasDescription() {
    return this.description.length > 0
  }
}
