<template>
  <TitledCard :title="$t('vorstossCard')">
    <span class="d-block" data-testid="open-vorstoesse-count">{{
      $t('openVorsoesse', { count: openVorstoesseCount })
    }}</span>
    <span class="d-block" data-testid="submitted-vorstoesse-count">{{
      $t('submitteVorsoesse', { count: submittedVorstoesseCount })
    }}</span>
    <template #actions>
      <v-btn data-testid="create-vorstoss-button" text color="primary" @click="openVorstossDialog">{{
        $t('newVorstossButton')
      }}</v-btn>
      <v-btn
        data-testid="show-vorstoesse-button"
        text
        :to="{ name: 'vorstoesse', params: { id: meeting.id } }"
        color="primary"
        >{{ $t('showVorstoesseButton') }}</v-btn
      >
    </template>
    <VorstossDialog ref="vorstossDialog" @update="fetchVorstoesse" />
  </TitledCard>
</template>
<script>
import { createLink } from '@/api/helpers'
import { api } from '@/api'
import size from 'lodash/size'
import reject from 'lodash/reject'
import filter from 'lodash/filter'

export default {
  name: 'VorstossCard',
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vorstoesse: [],
    }
  },
  computed: {
    openVorstoesseCount() {
      return size(reject(this.vorstoesse, { status: 'SUBMITTED' }))
    },
    submittedVorstoesseCount() {
      return size(filter(this.vorstoesse, { status: 'SUBMITTED' }))
    },
  },
  mounted() {
    this.fetchVorstoesse()
  },
  methods: {
    openVorstossDialog() {
      this.$refs.vorstossDialog.openWithMeeting(this.meeting)
    },
    async fetchVorstoesse() {
      const { data } = await api.get(createLink('meetings/{id}/vorstoesse', { id: this.meeting.id }))
      this.vorstoesse = data
    },
  },
}
</script>
